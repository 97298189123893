import React, { useEffect, useState } from 'react'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import SectionBoxReady from '../../components/Platfrom/SectionBoxReady'
import ImageTextBlock from '../../components/Blocks/ImageTextBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const Competitors = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const pageName = 'Competitors Monitoring'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]
     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'Identify Competitors Browsing Your Website',
          // description: 'No more anonmyous data that leaves you wondering "what\'s really working?"...',
          substring: 'Gain competitive insights and monitor activity in ways you never thought possible.',
     }
     const headerIntroCard = {
          description: `<span>Sometime, "you don't know what you don't know, until you know it!" </span><span>ListenLayer helps you to know it.</span>`,
          fName: 'Zachary Randall',
          fPosition: 'Founder & CEO, ListenLayer',
          imageURL: '/images/hero-image.png',
          imageAlt: 'Zachary Randall',
     }
     const headerIntroStep = [
          {
               step: 'Step 1:',
               title: 'Enable Reveal',
               description: 'Use ListenLayer to identify and reveal companies and people that are actively browsing your website.',
          },
          {
               step: 'Step 2:',
               title: 'Define Competitors',
               description: 'Build lists of competitors based on domain names. Organize these lists by products, sector, priority, and more.',
          },
          {
               step: 'Step 3:',
               title: 'Learn & Analyze',
               description: 'Monitor activity and learn about how your competitors browse your website and interact with your marketing.',
          },
     ]
     const dataImageTextBlock = [
          {
               // iconSvg: '/images/Shape4x.png',
               title: 'ListenLayer Reveal',
               description:
                    'Learn more about how we identify and reveal real people and companies alongside your website traffic and analytics data.',
          },
          {
               // iconSvg: '/images/swap-arrows-duotone.svg',
               title: `It's <span class="underline-v2">Your Data</span> ... Own It!`,
               description:
                    'ListenLayer does not sell or use your data for our own benefit (unlike those "free" analytics tools). Your data belongs to you - which is why we\'re able to allow you to identify your own leads and customers!',
          },
          {
               // iconSvg: '/images/swap-arrows-duotone.svg',
               title: 'Security-first Architecture',
               description:
                    'We take the security of your data seriously, with full encryption, access controls, and proactive measures. ListenLayer is currently pursuing SOC2, Type 2 compliance to reflect our commitment to security.',
          },
          {
               // iconSvg: '/images/swap-arrows-duotone.svg',
               title: 'Privacy Compliance',
               description:
                    'How we identify users and store data enables compliance with ever-changing privacy laws. Our architecture supports GDPR, CCPA/CPRA, HIPAA, and other regulatory requirements, and ListenLayer can be used to completely replace (or augment) other Consent Managment Platforms.',
          },
     ]

     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems} />
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard} headerIntroStep={headerIntroStep} />
               <section>
                    <div className="container">
                         <div className="wall-of-text">
                              <p>
                                   <span>
                                        Your competitors are browsing your website. They are interacting with your ads, downloading your content, and
                                        filling out forms. Some competitors are very passive, and other are very active (and aggressive).
                                   </span>
                                   <span>
                                        Use ListenLayer's Reveal feature to identify companies that are browsing your website within your anonymous
                                        traffic. You can even identify real people in your analytics when they share their data with you.
                                   </span>
                                   <span>
                                        Building a list of competitors will allow you to gain insight into how they are "spying" on your content and
                                        marketing efforts. Then take action to combat their moves.
                                   </span>
                              </p>
                         </div>
                    </div>
               </section>
               <SectionBoxReady />
               <section className="enhance-imgtext-block enhance-img-text">
                    <div className="container">
                         <ImageTextBlock dataImageTextBlock={dataImageTextBlock} />
                    </div>
               </section>
               <SectionBoxReady />
          </Layout>
     )
}

export default Competitors
